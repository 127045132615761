import Logotype from "./logotype";

const Phone = ({ color = "#ffffff" }: { color?: string }) => {
  return (
    <div className="border-blue-500 border-0 ">
      <div className="lg:w-[480px] flex aspect-[522/1128] flex-col items-center justify-center rounded-[64px] border-0 border-slate-500 bg-[url('/new-splash.png')] bg-contain">
        <div className="w-full">
          <div className="pl-4">
            <Logotype color={color} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Phone;
