import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// export const asError = (thrown: unknown): Error => {
//   if (thrown instanceof Error) return thrown;
//   try {
//     return new Error(JSON.stringify(thrown));
//   } catch {
//     // fallback in case there's an error stringifying.
//     // for example, due to circular references.
//     return new Error(String(thrown));
//   }
// };
