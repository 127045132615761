const LouvelleLogo = ({
	color = '#333333',
	width = '260px',
}: {
	color?: string
	width?: string
}) => {
	return (
		<div style={{ width }}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 260 34"
				fill={color}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					d="M45.022 0c-9.918 0-16.747 7.15-16.747 16.837 0 9.689 6.829 16.837 16.747 16.837s16.796-7.148 16.796-16.837C61.818 7.15 54.94 0 45.022 0ZM56.6 16.837c0 8.858-5.029 13.748-11.578 13.748-6.5 0-11.578-4.89-11.578-13.748S38.514 3.09 45.022 3.09c6.557 0 11.578 4.89 11.578 13.747ZM1.43 4.29v25.005c0 1.2 0 1.85-1.43 3.69h20.477l.74-6.459c-3.96 3.46-5.12 3.46-7.01 3.46H6.27V4.38c0-1.2 0-1.84 1.569-3.69H0c1.43 1.85 1.43 2.449 1.43 3.6Zm71.457 16.426V4.29c0-1.15 0-1.75-1.43-3.599h8.21c-1.94 1.89-1.94 2.49-1.94 3.739v16.566c0 5.768 3.32 9.318 8.718 9.318 5.21 0 8.44-3.55 8.44-9.318V4.339c0-1.2 0-1.75-2.03-3.649h6.828c-1.422 1.89-1.422 2.49-1.422 3.739v16.196c0 8.398-4.61 13.057-12.596 13.057-8.07 0-12.778-4.569-12.778-12.967ZM115.968.69h-8.208c1.141 1.773 1.507 2.66 2.233 4.418l.002.004.213.517 11.34 27.733h2.539l11.258-27.495.232-.543c.8-1.873 1.182-2.767 2.446-4.626h-6.549c1.521 2.12 1.381 2.54.691 4.2l-8.488 21.356-8.489-21.316c-.6-1.528-.879-2.267.78-4.248Zm31.653 28.604V4.29c0-1.15 0-1.75-1.43-3.599h21.406v5.63c-2.908-2.72-3.878-2.72-5.489-2.72h-9.639v10.887h7.889c1.479 0 2.26 0 4.659-1.8v6.459c-2.399-1.75-3.23-1.75-4.659-1.75h-7.889v12.687h8.859c1.799 0 2.859 0 6.738-3.09l-.74 6H146.2c1.421-1.85 1.421-2.499 1.421-3.699ZM179.406 4.29v25.005c0 1.2 0 1.85-1.437 3.69h20.485l.74-6.459c-3.961 3.46-5.12 3.46-7.01 3.46h-7.937V4.38c0-1.2 0-1.84 1.569-3.69h-7.839c1.429 1.85 1.429 2.449 1.429 3.6Zm30.076 25.005V4.29c0-1.15 0-1.75-1.43-3.599h7.839c-1.569 1.85-1.569 2.49-1.569 3.69v25.605h7.937c1.89 0 3.049 0 7.01-3.46l-.74 6.459h-20.485c1.438-1.84 1.438-2.49 1.438-3.69ZM239.556 4.29v25.005c0 1.2 0 1.85-1.422 3.698h21.127l.739-5.999c-3.878 3.09-4.938 3.09-6.738 3.09h-8.858V17.395h7.888c1.43 0 2.26 0 4.659 1.75v-6.458c-2.399 1.8-3.18 1.8-4.659 1.8h-7.888V3.6h9.638c1.611 0 2.581 0 5.49 2.72V.69h-21.406c1.43 1.85 1.43 2.449 1.43 3.6Z"
					clipRule="evenodd"
					style={{
						fill: color,
					}}
				/>
			</svg>
		</div>
	)
}
export default LouvelleLogo
